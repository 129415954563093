import * as React from "react"
import Layout from "../components/layout"
import PrivacyPolicy from "../components/Index/PrivacyPolicy"

const Privacy = () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}
export default Privacy
