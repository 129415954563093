import React from "react"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const PrivacyPolicy = () => {
  return (
    <div id="main-wrapper">
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h1 className="breadcrumb-title">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-wrapper-reveal">
        {/* <!--====================  Conact us Section Start ====================--> */}
        <div className="contact-us-section-wrappaer section-space--pt_40 section-space--pb_70">
          <div className="section-title-wrap section-space--mb_60">
            <div className="container">
              <div className="row align-items-left">
                <div className="col-lg-6 col-lg-6"></div>
                <h1 className="heading">
                  AIM OF THE{" "}
                  <span className="text-color-primary"> PRIVACY POLICY</span>
                </h1>

                <p style={{ paddingTop: "10px" }}>
                  Aamanto Technologies is fully committed to comply with
                  applicable data protection laws, as we believe that ensuring
                  data protection is the basis of trustworthy business
                  relationships. We are always working to stay compliant -
                  encourage audits, certifications, and provide
                  industry-standard contractual protections. Our privacy policy
                  is based upon the values set by the Canadian Standards
                  Association's Model Code for the Protection of Personal
                  Information and Canada's Personal Information Protection and
                  Electronic Documents Act (PIPEDA).
                </p>
                <ul>
                  <h3>PRINCIPLES OF PERSONAL DATA PROCESSING</h3>
                  <p style={{ paddingTop: "10px" }}>
                    We have to collect and use (to process) certain types of
                    information (Personal Data) that relates to the people (Data
                    Subjects) who we come into contact with in order to carry
                    out our business.
                    <br />
                    <br /> Therefore we presume and agree that Personal data
                    shall be:
                  </p>
                  <ol style={{ lineHeight: "30px" }}>
                    <li>
                      Processed lawfully, fairly and in a transparent manner in
                      relation to the data subject (‘lawfulness, fairness and
                      transparency’)
                    </li>
                    <li>
                      Collected for specified, explicit and legitimate purposes
                      and not further processed in a manner that is incompatible
                      with those purposes (‘purpose limitation’)
                    </li>
                    <li>
                      Adequate, relevant and limited to what is necessary in
                      relation to the purposes for which they are processed
                      (‘data minimisation’);
                    </li>
                    <li>
                      Accurate and, where necessary, kept up to date; every
                      reasonable step must be taken to ensure that personal data
                      that are inaccurate, having regard to the purposes for
                      which they are processed, are erased or rectified without
                      delay (‘accuracy’);
                    </li>
                    <li>
                      Kept in a form which permits identification of data
                      subjects for no longer than is necessary for the purposes
                      for which the personal data are processed (‘storage
                      limitation’);
                    </li>
                    <li>
                      Processed in a manner that ensures appropriate security of
                      the personal data, including protection against
                      unauthorised or unlawful processing and against accidental
                      loss, destruction or damage, using appropriate technical
                      or organisational measures (‘integrity and
                      confidentiality’).
                    </li>
                  </ol>
                </ul>
                <ul>
                  <h3>LAWFULNESS OF PROCESSING</h3>
                  <p style={{ paddingTop: "10px" }}>
                    Processing is necessary for the performance of a contract to
                    which the data subject is party or in order to take steps at
                    the request of the data subject prior to entering into a
                    contract.
                  </p>
                </ul>
                <ul>
                  <h3>HOW DO WE COLLECT YOUR PERSONAL INFORMATION</h3>
                  <p style={{ paddingTop: "10px" }}>
                    These are the main ways we collect your information:
                  </p>
                </ul>
                <ul className="dots">
                  <li>
                    You contact us directly via our website to request
                    information about our services.
                  </li>
                  <li>You reply to our direct marketing campaigns.</li>
                  <li>
                    We acquired your personal data from other sources, such as
                    social media sites.
                  </li>
                </ul>
                <p>
                  If you are under 16, please do not provide us with any of your
                  information unless you have the permission of your parent or
                  guardian.
                  <br />
                  <br /> Please help us to keep your information up to date by
                  informing us of any changes to your contact details as we
                  respect your right to obtain the rectification of inaccurate
                  personal data and the right to have incomplete personal data
                  completed.
                </p>
                <ul>
                  <h3>WHAT PERSONAL DATA MAY BE COLLECTED</h3>
                  <p style={{ paddingTop: "10px" }}>
                    The following types of personal information about you may be
                    collected:
                  </p>
                </ul>
                <ul className="dots">
                  <li>Name</li>
                  <li>Address</li>
                  <li>Phone numbers</li>
                  <li>E-mail address</li>
                </ul>
                <ul>
                  <h3>HOW DO WE KEEP PERSONAL DATA SAFE</h3>
                  <p style={{ paddingTop: "10px" }}>
                    We believe that personal information must be collected and
                    dealt with appropriately, thus there must be safeguards in
                    place to ensure this. Although data transmission over the
                    Internet cannot be guaranteed to be secure, we work hard to
                    maintain physical, electronic and procedural safeguards to
                    protect your information in accordance with applicable data
                    protection requirements. We use technical and organizational
                    security measures including encryption and authentication
                    tools to protect your personal information, against
                    manipulation, loss, destruction, and access by third
                    parties. Our main security measures are:
                  </p>
                </ul>
                <ul className="dots">
                  <li>
                    restricted access to your data on a “need to know” basis
                  </li>
                  <li>transfer of data only in encrypted form</li>
                  <li>firewalled IT systems to prohibit unauthorised access</li>
                  <li>
                    permanently monitored access to IT systems to detect and
                    stop any misuse.
                  </li>
                </ul>
                <ul>
                  <h3>YOUR RIGHTS</h3>

                  <p style={{ paddingTop: "10px" }}>
                    PIPEDA provides a general right of access to personal
                    information. PIPEDA Section 4.9 states:
                    <br />
                    “Upon request, an individual shall be informed of the
                    existence, use, and disclosure of his or her personal
                    information and shall be given access to that information.”
                    <br />
                    Section 4.9 also provides a right to rectification:
                    <br /> “An individual shall be able to challenge the
                    accuracy and completeness of the information and have it
                    amended as appropriate.”
                    <br />
                    There is also a limited right to erasure:
                    <br /> “Depending upon the nature of the information
                    challenged, amendment involves the correction, deletion, or
                    addition of information.”
                    <br /> In order to exercise your rights, please contact us
                    at: <a href="mailto:info@aamanto.com">info@aamanto.com</a>
                    Additional Information
                  </p>
                </ul>
                <ul>
                  <h4>Additional Information</h4>
                  <h4 style={{ paddingTop: "40px" }}>Cookie</h4>
                  <p style={{ paddingTop: "10px" }}>
                    A cookie is a small computer file or piece of information
                    that may be stored in your computer's hard drive when you
                    visit our websites. We may use cookies to improve our
                    website’s functionality and, in some cases, to provide
                    visitors with a customized online experience.
                    <br />
                    <br /> Cookies are widely used, and most web browsers are
                    configured initially to accept cookies automatically. You
                    may change your Internet browser settings to prevent your
                    computer from accepting cookies or to notify you when you
                    receive a cookie so that you may decline its acceptance.
                    Please note, however, if you disable cookies, you may not
                    experience optimal performance of our website.
                  </p>
                </ul>
                <ul>
                  <h4>
                    <i>Other Websites</i>
                  </h4>
                  <p style={{ paddingTop: "10px" }}>
                    Our website may contain links to other third-party sites
                    that are not governed by this privacy policy. Although we
                    endeavour to only link to sites with high privacy standards,
                    our privacy policy will no longer apply once you leave our
                    website. Additionally, we are not responsible for the
                    privacy practices employed by third party websites.
                    Therefore, we suggest that you examine the privacy
                    statements of those sites to learn how your information may
                    be collected, used, shared and disclosed.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PrivacyPolicy
